:root {
    //@include media( --bs-gutter-x, (7%,7%,6%,5%));

   --bs-body-font-family: var(--body-font-family);
   --bs-body-font-size: 14px;
  --bs-body-font-weight: 400;
   --bs-body-line-height: 17px;
   --bs-body-color: #21272D;
   --bs-body-bg: #fff;

   /*font*/
    --body-font-family: 'Inter', sans-serif;
    --font-weight-bold: 700;
    --font-weight-semiBold: 600;
    --font-weight-medium: 500;
    --font-weight-regular: 400; 
}