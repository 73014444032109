.cstm-model-wrapper {
    background-color: #ffffff;
    .cstm-model-header {
        padding: 7px 30px 14px;
        border-bottom: 1px solid #EBEAEA;
    }
    .cstm-model-body {
        padding: 20px 20px 30px;
        &.tracking-process {
            padding-top: 40px;
        }
    }
    .form-control, .form-select {
        min-height: 41px;
    }
    .form-item {
        padding-bottom: 20px;
    }
}
.destination-with-timeline {
    padding-left: 68px;
    position: relative;
}
.delete-more-destination-btn {
    position: absolute;
    bottom: 48px;
    right: -30px;
}

.addmore-location-item {
    margin-top: -23px;
    position: relative;
    z-index: 2;
}

.add-ship-footer {
    padding-top: 70px;
    button{height: 43px;}
}
.input-group-with-date {
    border-radius: 6px;
    padding: 8px 18px;
    font-size: 15px;
    line-height: 20px;
    color: #808080;
    border: 1px solid #DBD7F4;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 41px;
    img{display: none;}
    .calender-icon-btn {
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: transparent;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
    .destination-input {
        font-size: 14px;
        line-height: 17px;
        color: #11142D;
        border: 0;
        padding: 0;
        box-shadow: none !important;
        outline: none;
        &::placeholder {
            color: #808080;
        }
    }
    .destination-with-time {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: .5px;
        color: rgba(17, 20, 45, .6);
        border: 0;
        padding: 0;
        margin-top: 3px;
        outline: none;
    }
}
.timeline-icon {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        width: 38px;
        height: 38px;
        left: -68px;
        top: 0;
    }    
    &.start {
        &:after {
            background: url(/images/location.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:before {
            content: "";
            position: absolute;
            width: 38px;
            height: 100%;
            left: -68px;
            top: 0;
            background: url(/images/dash-vertical.svg);
            background-repeat: repeat-y;
            background-size: contain;
            background-position: center;
        }
    }
    &.destination {
        &:after {
            background: url(/images/destination.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}