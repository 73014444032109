
body {
    font-size: var(--bs-body-font-size) !important;
    line-height: var(--bs-body-line-height) !important;
    color: var(--bs-body-color) !important;
}
.form-control, .form-select {
    border-radius: 6px;
    padding: 8px;
    font-size: 15px;
    line-height: 20px;    
    border-color: #DBD7F4;
    color: #11142D;
    &:focus {
        border-color: #e7891b;
        box-shadow: 0 0 0 .25rem rgba(248, 149, 34, .25);
    }
    &::placeholder {
        color: #808080;
    }
}
.form-label {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .5px;
    color: #7F7F7F;
    > span {
        color: red;
    }
}
.btn-primary {
    background-color: #F89522 !important;
    border-color: #F89522 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: 500 !important;
    border-radius: 4px;
    &:hover, &:focus, &:active {
        background-color: #e7891b;
        border-color: #e7891b;
    }
    &:focus {
        border-color: #e7891b;
        box-shadow: 0 0 0 .25rem rgba(248, 149, 34, .25);
    }
}
.container-fluid {
    --bs-gutter-x: 60px;
}
.card {
    border-color: #E8EDF1;
    border-radius: 8px;
    color: #21272D;
    .card-body {
        padding: 21px 30px;
        @media screen and (max-width:767px) {
            padding: 20px;
        }
    }
}
.navbar{
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    height: 83px;
    z-index: 5;
}
h2 {
    margin: 0;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    letter-spacing: 0.05px;
}
h3 {
    font-size: 20px !important;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
}
table {
    width: 100%;
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    strong {
        font-weight: 600;
    }
    th, td {
        border: 1px solid #F1F1F3 !important;
        padding: 11px 16px;
        &:not(:last-child) {
            border-right: 0 !important;
        }
    }
    th {
        opacity: .6;
        border-bottom: 0 !important;
        background-color: #FCFCFD;
        &:first-child {
            border-top-left-radius: 8px;
        }
        &:last-child {
            border-top-right-radius: 8px;
        }
    }    
    tr {
        &:not(:last-child) {
            td {
                border-bottom: 0 !important;
            }
        }
        &:last-child {
            td {
                &:first-child {
                    border-bottom-left-radius: 8px;
                }
                &:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
    .actions {
        display: flex;
        align-items: center;
        gap: 14px;
        .btn-action {
            border: 0;
            background: none;
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
            img {
                height: 18px;
            }
        }
    }
}

.container-fluid {
    --bs-gutter-x: 100px !important;
    @media screen and (max-width:767px) {
        --bs-gutter-x: 70px !important;
    }
}
.card {
    border: 1px solid #E8EDF1 !important;
}