@import "../scss/variable";

.report-row {
    gap: 1.5%;
    padding-bottom: 19px;
    .report-item {
        background-color: #808080;
        flex: 0 0 50%;
        max-width: 170px;
        width: auto;
        padding: 16px 24px;
        border-radius: 8px;
        cursor: pointer;
        border:2px solid transparent;
        margin-bottom: .7rem;

        @media screen and (min-width:768px) and (max-width:1200px) {
           max-width: 159px;
        }
        &:hover{
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            transition: 0.3s ease;
        }
 
        &.report {
            &--sea {
                background-color: #C9D1F0;
            }
            &--air {
                background-color: #D1ECF5;
            }
            &--land {
                background-color: #ECECCF;
            }
            &--completed {
                background-color: #E5ECF6;
            }
            &--ongoing {
                background-color: #E5ECF6;
            }
        }
        &.active {
            border: 2px solid $secondary;
        }
        .report-label {
            display: flex;
            font-size: 12px;
            line-height: 15px;
            .report-label-text {
                flex-grow: 1;
            }
            .report-label-icon {
                flex: 0 0 28px;
                height: 28px;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .report-value {
            color: #000000;
            font-size: 24px;
            line-height: 1;
            font-weight: 600;
            padding-top: 8px;
        }
    }
}