@import "scss/bs-override";
@import "scss/custom.scss";
@import "scss/root";
@import "scss/variable";

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 0;
  max-width: 700px;
  overflow: auto;
  max-height: 100%;
  right: 0;
  height: 100%;
  @media screen and (min-width: 769px) {
    min-width: 700px;
  }
}
app-main-layout {
  padding-top: 78px;
  display: block;
}
button.navbar-toggler.dropdown-toggle.mob {
  position: absolute;
  left: 180px;
  top: 30px;border: none;
  &::after{content: none;}
 }
.popup-content-alert {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 0;
  max-width: 700px;
  overflow: auto;
}

.invalid-field {
  color: red;
  font-size: 12px;
  float: left;
  width: 100%;
  padding-top: 2px;
  margin-bottom: 10px;
  margin-top: 5px;
  border-left: 10px solid;
  padding-left: 0.6rem;
  line-height: 14px;
  margin-top: 4px;
}

.w-100 {
  width: 100%;
}

/*sooraj*/
.no_data {
  color: #000;
  font-size: 1.2rem;
  opacity: 0.6;
  padding: 2rem 0;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 1.2rem;
}
.cursor-pointer {
  cursor: pointer;
}
