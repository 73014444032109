@import "font";
@import "root";
@import "bs-override";
@import "header";
@import "report";
@import "addshipmentmodal";

body {
  background-color: #F6F7FA !important;
  min-height: 100vh;
}
main {
  padding-top: 26px;
  padding-bottom: 26px;
}
.table-card-header {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width:568px) {
    align-items: start;
    flex-direction: column;
    gap: 7px;
  }
  .card-action-row {
    display: flex;
    gap: 14px;
  }
}
.status-pill {
  display: inline-block;
  color: #1c7d39;
  background-color: #e1f4ea;
  letter-spacing: 0.01px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 10px;
  border-radius: 4px;
}
.status-created {
  color: #111010;
  background-color: #e9dc6a;
}
.close-btn {
  border: 0;
  padding: 0;
  background-color: #312f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  > img {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
}
.btn-link {
  font-size: 14px;
  color: #f89522;
  background: none;
  text-decoration: none;
  border: 0;
  padding: 0;
}
.input-group-absolute {
  position: relative;
  .bt-input-abs {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    background-color: #eeeeee;
    color: #1b1f26;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 9px 4px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    border: 0;
    > img {
      flex: 0 0 22px;
      height: 22px;
      width: auto;
      object-fit: contain;
    }
    span {
      opacity: 0.72;
    }
    &:hover{
      background: #f09831;
      color: white;
    }
  }
}
button.btn-sm {
  height: 33px;
  padding-left: .8rem;
  padding-right: .8rem;
}
.search-form input#search-text {
  height: 33px;
  @media screen and (max-width:568px) {
    width: 170px;
  }
}
.navbar .dropdown-menu.show{
  width: 100%;
  li.nav-item a{padding: 1rem;}
}

.tracking-info-row {
  padding-bottom: 2%;
    flex-wrap: wrap;
    @media screen and (max-width:767px) {
      padding-bottom: 2%;
      flex-wrap: wrap;
    }
  .tracking-info-item {
    > span {
      display: block;
      opacity: 0.6;
      padding-bottom: 7px;
      font-size: 12px;
    }
    > strong {
      font-weight: 600;
    }
    width: auto;
    max-width: 138px;
    flex: auto;
    margin-bottom: 1rem;
  }
}
.tracking-timeline-status-ul {
  background: url(/images/dash-vertical.svg);
  background-repeat: repeat-y;
  padding: 0;
  margin: 0;
  background-position: 17px center;
  > li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &.start {
      position: relative;
      &:before {
        content: "";
        width: 38px;
        height: 38px;
        background-color: #ffffff;
        position: absolute;
        z-index: 2;
      }
    }
    > .tracking-timeline-icon {
      flex: 0 0 38px;
      width: auto;
      height: 38px;
      position: relative;
      object-fit: contain;
      z-index: 3;
    }
    > span {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      font-weight: 700;
    }
  }
}
